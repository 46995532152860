import React from 'react'
import { compose } from 'recompose'
import SEO from '../../../components/seo'
import { AuthUserContext, withAuthorization } from '../../../components/Session'
import PropTypes from "prop-types"
import { EditAccount } from '../../../Views'

const EditAccountData = (props) => {

	if (props['*'] === '') navigate('/404')

	return (
		<>
			<SEO title="Accounts" />
			<AuthUserContext.Consumer>
				{authUser => (
					 authUser.isAdmin ?  <div>
						<EditAccount path="/dashboard/accounts/edit-account/:id" authUser={authUser} accountId={props['*']} />
					</div> : navigate('/404')
				)}
			</AuthUserContext.Consumer>
		</>
	)
}


EditAccountData.propTypes = {
	"*": PropTypes.string,
  }
  
const condition = authUser => !!authUser

export default compose(
	withAuthorization(condition),
)(EditAccountData)
